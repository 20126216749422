<template>
	<div>
		<header class="mb-5">
			<nav class="navbar navbar-expand-lg px-5">
				<a class="navbar-brand mx-auto" href="#">
					<img :src="logo" alt="bosCOD | Platform Kurir COD/Non-COD" width="auto" height="50">
				</a>
				<button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
					<span class="navbar-toggler-icon"></span>
				</button>

				<div class="collapse navbar-collapse" id="navbarSupportedContent">
					<ul class="navbar-nav mx-auto">
						<li class="nav-item">
							<a class="nav-link" href="https://boscod.com/#layanan">Layanan</a>
						</li>
						<li class="nav-item">
							<a class="nav-link" href="https://boscod.com/#alur">Alur Pengiriman</a>
						</li>
						<li class="nav-item active">
							<a class="nav-link" :href="url + '/ongkir'">Cek Ongkir</a>
						</li>
						<li class="nav-item">
							<a class="nav-link" :href="url + '/lacak'">Lacak</a>
						</li>
					</ul>
					<div class="form-inline d-flex justify-content-around my-2 my-lg-0">
						<a class="btn btn-outline-primary rounded my-2 my-sm-0 mx-2 text-primary" href="login">Masuk</a>
						<a class="btn btn-primary rounded my-2 my-sm-0 mx-2 text-light" href="order/create/single">Mulai Kirim</a>
					</div>
				</div>
			</nav>
		</header>
		<main role="main" class="bg-primary py-5">
			<section class="container">
				<h3 class="font-weight-bold text-center mb-3">Cek Tarif Ongkir Terbaik</h3>
				<form role="form" @submit="submitForm" class="card border rounded">
					<div class="card-body">
						<div class="row text-dark">
							<div class="col-12 col-md form-group mx-2">
								<label for="no_resi">Asal<span class="text-danger">*</span></label>
								<v-select label="name" name="origin" :filterable="false" :options="origins" v-model="form.origin" :reduce="(opt) => opt.id" @search="onSearch1" placeholder="Kecamatan, Kota/Kabupaten" required>
									<template slot="no-options"> Ketik asal pengiriman... </template>
									<template slot="option" slot-scope="option">
										<div class="d-center">
											{{ option.lokasi }}
										</div>
									</template>
									<template slot="selected-option" slot-scope="option">
										<div class="selected d-center">
											{{ option.lokasi }}
										</div>
									</template>
								</v-select>
							</div>
							<div class="col-12 col-md form-group mx-2">
								<label for="no_resi">Tujuan<span class="text-danger">*</span></label>
								<v-select label="name" name="destination" :filterable="false" :options="destinations" v-model="form.destination" :reduce="(opt) => opt.id" @search="onSearch2" placeholder="Kecamatan, Kota/Kabupaten" required>
									<template slot="no-options"> Ketik tujuan pengiriman... </template>
									<template slot="option" slot-scope="option">
										<div class="d-center">
											{{ option.lokasi }}
										</div>
									</template>
									<template slot="selected-option" slot-scope="option">
										<div class="selected d-center">
											{{ option.lokasi }}
										</div>
									</template>
								</v-select>
							</div>
							<div class="col-12 col-md form-group mx-2">
								<label for="no_resi">Berat</label>
								<div class="input-group rounded">
									<input type="number" step="0.1" min="0.5" name="no_resi" id="no_resi" v-model="form.weight" placeholder="Contoh : 1 - 99" class="form-control rounded-left border-right-0" required />
									<div class="input-group-append">
									  <div class="input-group-text bg-transparent border-left-0">Kg</div>
									</div>
								</div>
							</div>
							<div class="col-auto form-group mx-2 d-flex align-items-end">
								<button type="submit" :disabled="checking" name="submit" id="submit" class="btn btn-primary rounded">Cek Ongkir</button>
							</div>
						</div>
					</div>
				</form>
			</section>
		</main>
		<main role="main" class="py-5" if="resultOngkir.length > 0">
			<section class="container">
				<div class="overlay w-100 text-center" v-if="checking"><h1 style="font-size:40px;">Sedang diproses <i class="fas fa-sync-alt fa-spin"></i></h1></div>
				<div class="card border rounded" v-for="item in resultOngkir">
					<div class="card-body">
						<div class="row">
							<div class="col-sm-12 col-md">
								<img :src="getKurirLogo(item.kurir)" style="height:40px;" />
							</div>
							<div class="col-sm-12 col-md">
								<div class="description-block text-left">
									<span class="text-muted">Service</span>
									<h5 class="text-bold">{{ item.kurir + ' ' + item.service }}</h5>
								</div>
							</div>
							<div class="col-sm-12 col-md">
								<div class="description-block text-left">
									<span class="text-muted">Estimasi</span>
									<h5 class="text-bold">{{ item.etd_from == 0 || item.etd_thru == 0 ? '&#8212;' : item.etd_from + '-' + item.etd_thru  + ' hari' }}</h5>
								</div>
							</div>
							<div class="col-sm-12 col-md">
								<div class="description-block text-left" :title="'Diskon '+item.percent_disc+'%'">
									<span class="text-muted">Tarif</span>
									<h5 class="text-bold"><del class="text-danger">{{ formatPrice(item.maxPrice) }}</del> <span class="text-success">{{ formatPrice(item.price - item.disc) }}</span></h5>
								</div>
							</div>
							<div class="col-sm-12 col-md-auto d-flex align-items-center justify-content-end" v-if="false">
								<a type="button" :id="item.kurir + '/' + item.service" class="btn btn-primary text-light rounded" href="order/create/single">Buat Pesanan</a>
							</div>
						</div>
					</div>
				</div>
			</section>
		</main>
	</div>
</template>
<script>
import Vue from 'vue'
import { formatCurrency } from "@/libs/hxcore";
import $ from "jquery";
import Swal from "sweetalert2";
import vSelect from 'vue-select';
import "vue-select/dist/vue-select.css";
export default {
	name: "Cek Ongkir",
	components: {
		vSelect,
	},
	data() {
		return {
			url: window.location.origin,
			logo: '',
			origins: [],
			destinations: [],
			form: {
				origin: '',
				destination: '',
				weight: 1
			},
			resultOngkir: [],
			checking: false,
		};
	},
	created: function(){
		if(Vue.config.appHost == 'xpress')
			this.logo = require(`../assets/img/logo-xpress.png`);
		else this.logo = require(`../assets/img/logo-min.svg`);
	},
	methods: {
		onSearch1(search, loading) {
			if (search.length > 3) {
				loading(true);
				//this.search(loading, search, this);
				var vm = this;
				fetch(window.$apiUrl + `/publik/city?q=${escape(search)}`).then((res) => {
					res.json().then((json) => (vm.origins = json.items));
					loading(false);
				});
			}
		},
		onSearch2(search, loading) {
			if (search.length > 3) {
				loading(true);
				//this.search(loading, search, this);
				var vm = this;
				fetch(window.$apiUrl + `/publik/city?q=${escape(search)}`).then((res) => {
					res.json().then((json) => (vm.destinations = json.items));
					loading(false);
				});
			}
		},
		submitForm: function(ev) {
			ev.preventDefault();
			let self = this;
			if (!self.form.origin) {
				self.resultOngkir = [];
				return Swal.fire("Asal gudang belum dipilih!", ``, "error");
			}
			if (!self.form.destination) {
				self.resultOngkir = [];
				return Swal.fire("Tujuan pengiriman belum dipilih!", ``, "error");
			}
			this.loadOngkir(self.form);
		},
		loadOngkir(params) {
			const self = this;
			let data;
			data = Object.keys(params)
			.map(
				(key) =>
				encodeURIComponent(key) + "=" + ((params[key] ===false || params[key] === undefined)?0 : encodeURIComponent(params[key]))
			)
			.join("&");
			this.checking = true;
			fetch(window.$apiUrl + `/publik/ongkir?` + data).then((res) => {
				res.json().then((json) => {
					console.log("data ", json);
					self.resultOngkir = json;
					this.checking = false;
				});
			});
		},
		getKurirLogo(kurir) {
			const fileName = kurir.toLowerCase();
			return require(`../assets/img/${fileName}.png`); // the module request
		},
		formatPrice(data){
			return formatCurrency(data);
		},
	},
	mounted() {
		if (this.$route.params.id) {
			this.orderCode = this.$route.params.id;
			this.loadInfo(this.orderCode);
			this.loadHistory(this.orderCode);
		}
	}
};
$(function () {
});
</script>
<style scoped src="../assets/login.css"></style>
<style scoped>
:root {
	--text-dark: #7A7A7A;
}
.navbar-nav .nav-item .nav-link {
	color: var(--text-dark) !important;
	font-weight: normal;
}
@media screen and (max-width: 767px) {
	.navbar-nav > li {
		width: auto;
	}
}
.navbar-nav .nav-item.active .nav-link {
	color: var(--text-dark) !important;
	font-weight: bold;
}
.pr-track {
	width: 100%;
	vertical-align: top;
	display: flex;
	flex-direction: column;
	min-height: 60px;
	padding-right: 0;
	padding-left: 0;
}
.ls-track {
	width: 100%;
	vertical-align: top;
	display: flex;
	min-height: 60px;
}
.pr-track .line-wrapper {
	flex-basis: 0px;
	margin: 16px 0px;
	-webkit-box-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	align-items: center;
	flex-direction: column;
	width: 100%;
	display: flex;
}
.ls-track .line-wrapper {
	flex-basis: 0px;
	margin: 0px 16px;
	-webkit-box-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	align-items: center;
	flex-direction: column;
	width: 3%;
	display: flex;
}
.pr-track:nth-child(1) .circle {
	background-color: transparent;
	animation: blinker 1s linear infinite;
}
.ls-track:first-child .circle {
	background-color: transparent;
	animation: blinker 1s linear infinite;
}
.pr-track .circle {
	width: 15px;
	height: 15px;
	border-radius: 50%;
	position: relative;
	margin-top: 0.25rem;
	margin-bottom: auto;
	background-color: var(--N100, #dbdee2);
}
.ls-track .circle {
	width: 15px;
	height: 15px;
	border-radius: 50%;
	position: relative;
	margin-top: 0.25rem;
	margin-bottom: auto;
	background-color: var(--N100, #dbdee2);
}
.pr-track .line-wrapper .line {
	height: 1px;
	width: calc(100% - 9px);
	margin-top: -7.5px;
	background-image: linear-gradient(
		to right,
		var(--N100, #dbdee2) 55%,
		rgba(255, 255, 255, 0) 55%
	);
	background-position: left middle;
	background-size: 12px 1px;
	background-repeat: repeat-x;
}
.ls-track .line-wrapper .line {
	width: 1px;
	height: calc(100% - 9px);
	margin-bottom: -6px;
	background-image: linear-gradient(
		to bottom,
		var(--N100, #dbdee2) 55%,
		rgba(255, 255, 255, 0) 55%
	);
	background-position: left center;
	background-size: 1px 12px;
	background-repeat: repeat-y;
}
.ls-track:first-child .status-wrapper {
	padding-bottom: 36px;
}
.pr-track:nth-child(1) .status-wrapper .status {
	font-weight: 800;
	line-height: 1.4;
}
.ls-track:first-child .status-wrapper .status {
	font-weight: 800;
	line-height: 1.4;
}
.pr-track .status-wrapper .status {
	color: var(--N500, #52565e);
	line-height: 1.5;
	text-align: center;
}
.ls-track .status-wrapper .status {
	color: var(--N500, #52565e);
	line-height: 1.5;
	margin-top: 8px;
}
.ls-track .status-wrapper .date {
	color: var(--N200, #9fa6b0);
}
.pr-track:nth-child(1) .circle::before , .ls-track:first-child .circle::before {
	width: 15px;
	position: absolute;
	height: 15px;
	border-radius: 50%;
	background-color: var(--G300, #f9b79d);
	transition: all 0.5s ease-out 0s;
	animation-name: grow;
	animation-duration: 1400ms;
	animation-timing-function: ease-out;
	animation-iteration-count: infinite;
	animation-fill-mode: none;
	animation-play-state: running;
	content: "";
}
.pr-track:nth-child(1) .circle::after , .ls-track:first-child .circle::after {
	border-radius: 50%;
	background-color: var(--G500, #e96936);
	content: "";
	position: absolute;
	width: 11px;
	height: 11px;
	left: 2px;
	top: 2px;
}
.pr-track .circle::after , .ls-track .circle::after {
	border-radius: 50%;
	background-color: var(--N200, #9fa6b0);
	content: "";
	position: absolute;
	width: 9px;
	height: 9px;
	left: 3px;
	top: 3px;
}
.ls-track:last-of-type .line {
	display: none;
}
</style>
